import { DotLottieReact } from "@lottiefiles/dotlottie-react";

import CreatureWelcomeWave from "../../images/animated/creature-welcome-wave.json";

import { useState, useEffect } from "react";

export default function Welcome() {
  const [dotLottie, setDotLottie] = useState(null);

  const dotLottieRefCallback = (dotLottie) => {
    setDotLottie(dotLottie);
  };

  useEffect(() => {
    const onComplete = () => {
      setTimeout(() => {
        dotLottie.play();
      }, 2000);
    };

    if (dotLottie) {
      dotLottie.addEventListener("complete", onComplete);
    }
  }, [dotLottie]);

  return (
    <div className="absolute bottom-[220px] left-0">
      <DotLottieReact
        dotLottieRefCallback={dotLottieRefCallback}
        data={CreatureWelcomeWave}
        loop={false}
        autoplay
        style={{
          width: 188,
          height: 188,
        }}
      />
    </div>
  );
}
