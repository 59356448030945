import { Link, useNavigate } from "@remix-run/react";
import type { LoaderFunctionArgs } from "@remix-run/cloudflare";

import { useEffect } from "react";

import { useAuth } from "@clerk/remix";

import Layout from "../components/layout";
import Button from "../components/button";
import Welcome from "../components/screens/welcome";
import Loading from "../components/loading";

import BraidLogo from "../images/braid.png";

export async function loader({ context }: LoaderFunctionArgs) {
  console.log(context);
  return null;
}

export default function Index() {
  const { userId, isLoaded } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (userId && isLoaded) {
      navigate("/dashboard");
    }
  }, [userId, isLoaded, navigate]);

  const headerContent = (
    <div className="pt-5">
      <img src={BraidLogo} alt="braid logo" className="mx-auto w-[77px]" />
      <h1 className="text-3xl font-bold tracking-wide text-center font-nunito antialiased pt-4">
        see how well your <br />
        friends know you!
      </h1>
    </div>
  );

  const footerContent = (
    <div className="flex justify-between items-center">
      <div>
        <Link to="/sign-in" className="text-left text-purple text-md">
          already have an account?
        </Link>
      </div>
      <Button onClick={() => navigate("/getting-started")}>get started</Button>
    </div>
  );

  return (
    <div>
      {isLoaded && !userId ? (
        <Layout headerContent={headerContent} footerContent={footerContent}>
          <div className="relative min-h-dvh">
            <Welcome />
          </div>
        </Layout>
      ) : (
        <Loading />
      )}
    </div>
  );
}
